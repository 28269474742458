import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql, useStaticQuery} from 'gatsby';
import Qm from "../layouts/QM";


const QmFr = () => {
  const data = useStaticQuery(graphql`
    query qmDatasFr {
      image: file(relativePath: { eq: "qm/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulAvantagesGfa(sort: {fields: createdAt, order: ASC}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            functionalities {
              functionalities
            }
            gif {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulInstallationsGfa {
        edges {
          node {
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/queue-management'
    slider={true}
    headerImage={data.image.childImageSharp} 
    headerImageAlt="Ecran tactile et application Attractif"
    title="Découvrez notre solution de gestion de file d'attente" 
    subTitle="Nous vous accompagnons dans la réalisation clé en main de votre projet de gestion de file d'attente." 
    anchorLink="#touch-projects-types">
      <Seo 
        title="Borne d'affichage tactile et interactive"
        description="Nous réalisons des bornes tactiles et interactives sur-mesure ou personalisées selon vos besoins. Votre établissement offrira une borne d'affichage intuitive et attractive pour ses visiteurs."
       />
      <Qm lang="fr-CA" types={data.allContentfulAvantagesGfa} carouselDatas={data.allContentfulInstallationsGfa.edges[0].node}/>
    </Layout>
  )
}

export default QmFr
